import { useEffect, useState, useRef } from "react";
import { useOnScreen } from "./hooks/OnScreen";
import { useAnimation } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { store, switchRegistrationExchange } from "../Store/Store";
import InputMask from "react-input-mask";
import politic from "../docs/политика конфиденциальности.pdf"
// import { ExchangerRegistration } from "./ExchangerRegistration";

export const CryptoAdres = () => {
  const navigate = useNavigate();
  const controls = useAnimation();
  const rootRef = useRef();
  const onScreen = useOnScreen(rootRef);
  const languageStatus = useSelector((state) => state.currentLanguage);

  // const [accountStatus, setAccountStatus] = useState(
  //   localStorage.getItem("accountStatus") || false
  // );

  useEffect(() => {
    if (onScreen) {
      controls.start({
        x: 0,
        opacity: 1,
        transition: {
          duration: 2,
          ease: "easeOut",
        },
      });
    }
  }, [onScreen, controls]);
  const [number, setNumber] = useState("");
  const [addres, setAddres] = useState("");
  const [check, setCheck] = useState(false);
  const cardList = JSON.parse(localStorage.getItem("card_list")) || [];

  // const cryptoData = JSON.parse(localStorage.getItem("cryptoData")) || [];

  const sumStatus = useSelector((state) => state.currentTransitionSum);
  const numberStatus = useSelector((state) => state.currentNumber);
  const addresStatus = useSelector((state) => state.currentAddres);
  const dollarStatus = useSelector((state) => state.currentDollarStatus);

  function handleTransition(e) {
    e.preventDefault();
    // if (
    //   activeAddres == true &&
    //   activeNumber == false &&
    //   addres.length > 0 &&
    //   sumStatus.sum != 0 &&
    //   check
    // ) {
    //   navigate("/transition");
    // }

    if (dollarStatus) {
      alert("Минимальная сумма входа в сделку 100$ и эквивалент");
    }

    localStorage.setItem("cryptoAddres", addres);
    localStorage.setItem("cardNumber", number);
    if (
      localStorage.getItem("accountStatus") !== null &&
      addresStatus == false &&
      numberStatus == true &&
      number.length === 19 &&
      (sumStatus.sum != 0 || sumStatus.final != 0) &&
      check
    ) {
      window.scrollTo(0,0)
      cardList.push(number);

      localStorage.setItem("card_list", JSON.stringify(cardList));
      navigate("/transition");
    }
    if (
      localStorage.getItem("accountStatus") !== null &&
      addresStatus == true &&
      numberStatus == false &&
      addres.length > 0 &&
      (sumStatus.sum != 0 || sumStatus.final != 0) &&
      check
    ) {
      return;
    }

    if (
      localStorage.getItem("accountStatus") !== null &&
      addresStatus == true &&
      numberStatus == true &&
      number.length === 19 &&
      addres.length > 0 &&
      (sumStatus.sum != 0 || sumStatus.final != 0) &&
      check
    ) {
      return;
    }

    if (numberStatus && number.length !== 19) {
      alert("Полностью введите номер карты");
    }

    if (
      localStorage.getItem("accountStatus") == null &&
      addresStatus == false &&
      numberStatus == true &&
      number.length === 19 &&
      (sumStatus.sum != 0 || sumStatus.final != 0) &&
      check
    ) {
      cardList.push(number);
      console.log(cardList);

      localStorage.setItem("card_list", JSON.stringify(cardList));
      document.body.style.overflow = "hidden";
      store.dispatch(switchRegistrationExchange(true));
    }
  }

  return (
    <div>
      {" "}
      <div className="adresBox desktopExchanger" ref={rootRef}>
        <div className="cryptoAdresBox">
          <div className="cryptoPayment">
            {numberStatus && (
              <div className="paymentBox">
                <InputMask
                  className="paymentInput"
                  placeholder={
                    languageStatus ? "Enter card number" : "Введите номер карты"
                  }
                  value={number}
                  onChange={(e) => setNumber(e.target.value)}
                  mask={"9999 9999 9999 9999"}
                  maskChar={null}
                ></InputMask>
              </div>
            )}
            {addresStatus && (
              <div className="paymentBox">
                <input
                  className="paymentInput"
                  placeholder={
                    languageStatus
                      ? "Enter the recipient's address"
                      : "Введите адрес получателя"
                  }
                  value={addres}
                  onChange={(e) => setAddres(e.target.value)}
                ></input>
              </div>
            )}
          </div>

          <div className="politicBox">
            <div style={{ display: "flex", flexDirection: "row" }}>
              {" "}
              <input
                type="checkbox"
                className="politicCheck"
                onChange={(e) => {
                  if (e.target.checked) {
                    setCheck(true);
                  } else {
                    setCheck(false);
                  }
                }}
              ></input>
              <div style={{ marginLeft: "5px" }}>
                {languageStatus ? "I agree to" : "Согласен с"}{" "}
              </div>
            </div>

            <div style={{ textDecoration: "underline", cursor: "pointer" }} onClick={() => window.open(politic)}>
              {languageStatus
                ? "the privacy policy"
                : "политикой конфиденциальности"}
            </div>
          </div>
          <div className="startPayment">
            <button
              className="startExchange glowing"
              onClick={handleTransition}
            >
              {languageStatus ? "GET" : "ПОЛУЧИТЬ"}
            </button>
          </div>
        </div>
      </div>{" "}
    </div>
  );
};
