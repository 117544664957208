import axios from "axios";
import { useEffect, useState, useRef } from "react";
import ReactLoading from "react-loading";
import { Line } from "react-chartjs-2";
import { useOnScreen } from "./hooks/OnScreen";
import { motion, useAnimation } from "framer-motion";

import btc from "../icons/btc.png";
import eth from "../icons/eth.png";
import usdt from "../icons/usdt.png";
import usdc from "../icons/usdc.png";
import sol from "../icons/sol.png";

import btc_color from "../icons/btc_color.png";
import eth_color from "../icons/eth_color.png";
import usdt_color from "../icons/usdt_color.png";
import usdc_color from "../icons/usdc_color.png";
import sol_color from "../icons/sol_color.png";
import ton from "../icons/ton.png";
import { AboutGraph } from "./AboutGraph";
import { store, lkCoin } from "../Store/Store";
import { useSelector } from "react-redux";

export const CurrancyList = () => {
  return (
    <div className="pop_up">
      <div className="pop_window signWindow"></div>
    </div>
  );
};

export const CurancyLK = () => {
  const controls = useAnimation();
  const rootRef = useRef();
  const onScreen = useOnScreen(rootRef);
  useEffect(() => {
    if (onScreen) {
      controls.start({
        x: 0,
        opacity: 1,
        transition: {
          duration: 2,
          ease: "easeOut",
        },
      });
    }
  }, [onScreen, controls]);
  const [currancyList, setCurrancyList] = useState([]);
  const [loading, setLoad] = useState(false);
  const [selectVisible, setSelectVisible] = useState("none");
  const [bigGraph, setBigGraph] = useState(false);
  const [graphItem, setGraphItem] = useState();
  const languageStatus = useSelector((state) => state.currentLanguage);
  const selectCoins = [
    { title: "Bitcoin", value: "bitcoin", src: btc, colorSrc: btc_color },
    { title: "Ethereum", value: "ethereum", src: eth, colorSrc: eth_color },
    { title: "Tether", value: "tether", src: usdt, colorSrc: usdt_color },
    { title: "USD-coin", value: "usd-coin", src: usdc, colorSrc: usdc_color },
    { title: "Solana", value: "solana", src: sol, colorSrc: sol_color },
    { title: "TON", value: "the-open-network", src: ton, colorSrc: "" },
  ];
  const [graphDateList, setGraphDateList] = useState([
    { date: "1 день", active: false, current: "1" },
    { date: "7 дней", active: true, current: "7" },
    { date: "30 дней", active: false, current: "30" },
  ]);
  const [currentGraphDate, setCurrentGraphDate] = useState("7");
  const [moreCoinsIds, setMoreCoinsIds] = useState(
    JSON.parse(localStorage.getItem("selectList")) || []
  );
  const date = new Date();
  date.setDate(date.getDate() - 7);
  const coinRef = useSelector((state) => state.currentCoinRef);

  // const dateData = `${
  //   date.getDate() < 10 ? `0` + date.getDate() : date.getDate()
  // }-${
  //   date.getMonth() + 1 < 10 ? `0` + date.getMonth() : date.getMonth() + 1
  // }-${date.getFullYear()}`;

  useEffect(() => {
    languageStatus
      ? setGraphDateList([
          { date: "1 day", active: false, current: "1" },
          { date: "7 days", active: true, current: "7" },
          { date: "30 days", active: false, current: "30" },
        ])
      : setGraphDateList([
          { date: "1 день", active: false, current: "1" },
          { date: "7 дней", active: true, current: "7" },
          { date: "30 дней", active: false, current: "30" },
        ]);
  }, [languageStatus]);

  function executeCoinScroll(item) {
    store.dispatch(lkCoin(item.value));
    coinRef.current.scrollIntoView();
  }

  async function fetchData() {
    for (let item in moreCoinsIds) {
      let price;
      let oldPrice;
      let color;
      let name;
      let src;
      let colorSrc;

      selectCoins.map((elem) => {
        if (moreCoinsIds[item] == elem.value) {
          src = elem.src;
          colorSrc = elem.colorSrc;
        }
      });

      setLoad(true);
      await axios
        .get(
          `https://point1.bot-n.ru/graphs_coin_period?coin=${moreCoinsIds[item]}&period=${currentGraphDate}`
        )
        .then((response) => {
          setLoad(false);

          price = response.data.now_day.usd;
          oldPrice = response.data.history.market_data.current_price.usd;
          name = response.data.history.name;

          if ((1 - oldPrice / price) * 100 < 0) {
            color = "#e65069";
          }

          if ((1 - oldPrice / price) * 100 >= 0) {
            color = "#0abf64";
          }
          setCurrancyList((currancyList) => [
            ...currancyList,
            {
              name: name,
              value: moreCoinsIds[item],
              price: Math.round(price * 100) / 100,
              difference: (1 - oldPrice / price) * 100,
              color: color,
              history: response.data.graph.prices,
              src: src,
              colorSrc: colorSrc,
            },
          ]);
        });
    }
  }

  useEffect(() => {
    setCurrancyList([]);
    fetchData();
  }, [currentGraphDate]);

  useEffect(() => {
    if (bigGraph) {
      document.body.style.overflow = "hidden";
    }
    if (!bigGraph) {
      document.body.style.overflow = "";
    }
  }, []);

  function handleSelect() {
    if (selectVisible == "none") {
      setSelectVisible("grid");
    }
    if (selectVisible == "grid") {
      setSelectVisible("none");
    }
  }

  function dateClick(item) {
    setGraphDateList(
      graphDateList.map((listItem) => {
        if (item == listItem) {
          setCurrentGraphDate(listItem.current);
          return { ...listItem, active: true };
        } else {
          return { ...listItem, active: false };
        }
      })
    );
  }

  async function fetchAdd(target) {
    let price;
    let oldPrice;
    let color;
    let name;
    let src;
    let colorSrc;

    selectCoins.map((item) => {
      if (target == item.value) {
        src = item.src;
        colorSrc = item.colorSrc;
      }
    });

    setLoad(true);
    await axios
      .get(
        `https://point1.bot-n.ru/graphs_coin_period?coin=${target}&period=${currentGraphDate}`
      )
      .then((response) => {
        setLoad(false);
        price = response.data.now_day.usd;
        oldPrice = response.data.history.market_data.current_price.usd;
        name = response.data.history.name;

        if ((1 - oldPrice / price) * 100 < 0) {
          color = "#e65069";
        }

        if ((1 - oldPrice / price) * 100 >= 0) {
          color = "#0abf64";
        }
        setCurrancyList((currancyList) => [
          ...currancyList,
          {
            name: name,
            value: target,
            price: Math.round(price * 100) / 100,
            difference: (1 - oldPrice / price) * 100,
            color: color,
            history: response.data.graph.prices,
            src: src,
            colorSrc: colorSrc,
          },
        ]);
      });
  }

  const handleCheck = (event) => {
    localStorage.setItem("selectList", JSON.stringify([]));

    if (event.target.checked) {
      fetchAdd(event.target.value);
      setMoreCoinsIds([...moreCoinsIds, event.target.value]);
    }

    if (!event.target.checked) {
      setMoreCoinsIds(
        moreCoinsIds.filter((item) => event.target.value != item)
      );
      setCurrancyList(
        currancyList.filter((item) => item.value != event.target.value)
      );
    }
  };

  useEffect(() => {
    localStorage.setItem("selectList", JSON.stringify(moreCoinsIds));
  }, [moreCoinsIds]);

  function closeGraph(status) {
    setBigGraph(status);
  }

  return (
    <div>
      {" "}
      <motion.div
        className="currancyBox"
        ref={rootRef}
        initial={{ opacity: 0, y: 20 }}
        animate={controls}
      >
        <div className="title popularTitle">
          {languageStatus ? "EXCHANGE RATES" : "КУРСЫ ВАЛЮТ"}
        </div>
        <div className="selectCoin">
          <div className="selectBox">
            <button
              className="exchangeSelect"
              onClick={handleSelect}
              style={{
                margin: "0 auto",
                borderRadius: "5px",
              }}
            >
              {languageStatus ? "Change the list" : "Изменить список"}

              <svg className="strelka-bottom-3" viewBox="0 0 5 9">
                <path d="M0.419,9.000 L0.003,8.606 L4.164,4.500 L0.003,0.394 L0.419,0.000 L4.997,4.500 L0.419,9.000 Z"></path>
              </svg>
            </button>{" "}
            <ul
              className="exchangeOptions selectFavorite"
              style={{ display: selectVisible }}
            >
              {selectCoins.map((item) => {
                if (moreCoinsIds.includes(item.value)) {
                  return (
                    <li key={item.title} className="selectedCoin">
                      <div>
                        {" "}
                        <input
                          type="checkbox"
                          id={item.value}
                          value={item.value}
                          src={item.src}
                          onChange={handleCheck}
                          checked="true"
                        />
                        <img className="coinIcon" src={item.src} alt=""></img>
                      </div>

                      <label htmlFor={item.value}>{item.title}</label>
                    </li>
                  );
                } else {
                  return (
                    <li>
                      <div>
                        {" "}
                        <input
                          type="checkbox"
                          id={item.value}
                          value={item.value}
                          onChange={handleCheck}
                        />
                        <img className="coinIcon" src={item.src} alt=""></img>
                      </div>

                      <label htmlFor={item.value}>{item.title}</label>
                    </li>
                  );
                }
              })}
            </ul>
          </div>
        </div>
        {currancyList.length > 0 && (
          <div className="chooseDateBox">
            {graphDateList.map((item) => (
              <div
                key={item.current}
                className={`headerButton ${
                  item.active == true ? "chosenGraphDate" : ""
                }`}
                onClick={() => dateClick(item)}
              >
                {item.date}
              </div>
            ))}
          </div>
        )}
        {/* {currancyList.length>0 && (
          <div className="popularHeader">
            <span>ВАЛЮТА</span>
            <span>ЦЕНА</span>
            <span>ГРАФИК</span>
            <span>ИЗМЕНЕНИЯ</span>
            <span>КУПИТЬ</span>
          </div>
        )} */}

        <div className="currancyList">
          {currancyList.map((item) => {
            return (
              <div key={item}>
                {loading ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "10px",
                      minWidth: "100%",
                    }}
                  >
                    <ReactLoading
                      type="spinningBubbles"
                      color="white"
                      height={"50px"}
                      width={"50px"}
                    />
                  </div>
                ) : (
                  <div className="currancyRow">
                    <div className="rowBlock currancyTitle">
                      <img
                        className="cryptoCoin"
                        src={item.colorSrc}
                        alt=""
                      ></img>
                      {item.name}
                    </div>

                    <div className="rowBlock currancyPrice">
                      ${Math.round(item.price * 100) / 100}
                    </div>

                    <div
                      className="chart currencyChart"
                      onClick={() => {
                        setBigGraph(true);
                        setGraphItem(item);
                      }}
                    >
                      <Line
                        type="line"
                        width={20}
                        height={10}
                        data={{
                          labels: item.history.map((coin) => {
                            let date = new Date(coin[0]);
                            let time;
                            if (window.outerWidth > 650) {
                              time = `${date.getHours()}:${
                                date.getMinutes() < 10
                                  ? "0" + date.getMinutes()
                                  : date.getMinutes()
                              }`;
                            } else {
                              time = `${date.getDate()}.${
                                date.getMonth() + 1 < 10
                                  ? "0" + (date.getMonth() + 1)
                                  : date.getMonth() + 1
                              } ${date.getHours()}:${
                                date.getMinutes() < 10
                                  ? "0" + date.getMinutes()
                                  : date.getMinutes()
                              }`;
                            }
                            return time;
                          }),

                          datasets: [
                            {
                              data: item.history.map((coin) => coin[1]),
                              borderColor: `${item.color}`,
                              fill: true,
                              lineTension: 0.5,
                            },
                          ],
                        }}
                        options={{
                          legend: {
                            display: false,
                          },
                          scales: {
                            xAxes: [
                              {
                                ticks: {
                                  display: false,
                                },
                                gridLines: {
                                  display: false,
                                  color: "rgba(0, 0, 0, 0)",
                                },
                              },
                            ],
                            yAxes: [
                              {
                                ticks: {
                                  display: false,
                                },
                                gridLines: {
                                  display: false,
                                  color: "rgba(0, 0, 0, 0)",
                                },
                              },
                            ],
                          },
                          elements: {
                            point: {
                              radius: 1,
                            },
                          },
                        }}
                      />
                    </div>
                    <div className="rowBlock">
                      {Math.round(item.difference * 100) / 100}%
                    </div>

                    <button
                      className="startCurrancyExchange rowBlock glowing"
                      onClick={() => executeCoinScroll(item)}
                    >
                      {languageStatus ? "BUY" : "КУПИТЬ"}
                    </button>
                  </div>
                )}{" "}
              </div>
            );
          })}
        </div>

        {/* {moreCoinsIds.length > 3 && !moreStatus && (
      <div className="moreCurrancy" onClick={() => setMoreStatus(true)}>
        Показать больше
      </div>
    )}
    {moreCoinsIds.length > 3 && moreStatus && (
      <div className="moreCurrancy" onClick={() => setMoreStatus(false)}>
        Свернуть
      </div>
    )} */}
      </motion.div>{" "}
      {bigGraph && (
        <AboutGraph item={graphItem} close={closeGraph}></AboutGraph>
      )}
    </div>
  );
};
